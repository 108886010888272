body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  color: #333;
  background-color: #f6f8fa;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

html,
body,
#root {
  height: 100%;
}

html,
body {
  overflow: auto;
}

input {
  font-size: 14px !important;
}

textarea {
  width: 100% !important;
  border:1px solid #d4d8dd !important;
  padding: 10px 15px !important;
  border-radius: 4px;
}

th {
  text-transform: uppercase;
}

.login-box {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius:0.5rem;
  padding:15px;
}

@media screen and (max-width: 440px) {
  .login-box {
    padding: 0 15px;
  }
}
/* 
.login-input {
  width: 100%;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  margin-top: 16px !important;
  margin-bottom: 8px !important;
} */

.login-logo {
  width: 65px;
  height: 65px;
}

.login-box > h1 {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: 0em;
}

.login-box > form {
  width: 100%;
  margin-top: 8px;
}

.login-button {
  width: 100%;
  margin-top: 5px !important;
  margin-bottom: 16px !important;
}

.forgot-password {
  display: flex;
  justify-content: center;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: #5a8dee;
  text-decoration: underline;
  text-decoration-color: rgba(25, 118, 210, 0.4);
  text-align: center;
}

.copyright {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 64px;
  margin-bottom: 32px;
}

.company-underline {
  margin: 0;
  color: inherit;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

#bg {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

#bg > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
}

.side-menu {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  width: 250px;
  height: 100%; 
  box-sizing: border-box;
  webkit-transition: all .1s ease 0s;
  transition: all .1s ease 0s;
  -o-transition: all .1s ease 0s;
  overflow: hidden;
  white-space: nowrap;
  /* background-image: url("../images/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
}

.upper-menu {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid rgb(235, 237, 242);
}

[hidden] {
  display: none !important;
}

#user-table {
  width: 100%;
}
#copyright-placeholder {
  width: calc(100% - 250px);
  left:250px;
}
#copyright-placeholder.expand {
  left:57px;
  width: calc(100% - 57px);
}
@media screen and (max-width: 603px) {
  .avatar-panel {
    padding-top: 25px !important;
  }
}

@media screen and (max-width: 501px) {
  .side-menu {
    width: 0px !important;
  }
  .main {
    margin: 0 !important;
  }
  #copyright-placeholder {
    padding: 10px !important;
  }
}

@media screen and (max-width: 1100px) {
  .side-menu {
    width: 57px;
    position: fixed;
  }
  #menu-icon {
    display: inline-flex !important;
  }
  #user-table {
    min-width: 100% !important;
  }
  .main {
    margin-left: 57px;
  }
  .force-expand {
    width: 280px !important;
    z-index: 3;
  }
  #copyright-placeholder {
    left:57px;
    width: calc(100% - 57px);
  }
}
@media(max-width:480px){
  #copyright-placeholder {
    left:0;
    width: 100%;
  }
}
@media screen and (min-width: 1100px) {
  .expand {
    width: 250px;
  }
  .display {
    display: none !important;
  }
}

.display {
  display: block !important;
}

@media screen and (max-width: 700px) {
  #hotline,
  #rights {
    display: none !important;
  }
}

@media screen and (max-width: 730px) {
  .ul-heading-placeholder {
    flex-wrap: wrap;
    
  }
  #search-bar {
    width: 226px;
  }
  .ul-heading {
    min-width: 125px !important;
  }
}

.close {
  width: 57px;
}

.show {
  display: inline-flex !important;
}

.main {
  position: relative;
  z-index: 2;
  flex: 1;
  background-color: #f6f8fa;
  transition: 400ms all;
  height: calc(100% - 60.5px);
  overflow-y: scroll;
  overflow-x: hidden;
}

@media(min-width:768px){
  .main::-webkit-scrollbar {
    width: 3px;
    background-color: #eee; }
  
  .main::-webkit-scrollbar-thumb {
    background-color: #e5edfc;
    border-radius: 999px; }
  }

.content {
  height: 100%;
  display: flex;
  flex-direction: row;
  box-shadow:0 4px 25px 0 rgb(168 180 208 / 10%);
}

.logo-img {
  margin-top: 4px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  /* filter: brightness(0) invert(0.25); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.logo {
  color: #4b495e;
  display: flex;
  flex-direction: row;
  margin-top: 1px;
  width: 100%;
  padding: 14px;
  padding-left: 13px;
}

.logo h1 {
  font-size: 1.125rem;
  margin: 0;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.logo > #left-arrow {
  font-size: 40px;
  margin-left: 10px;
}

.screen {
  padding: 15px;
  margin:0 auto;
}
@media (min-width: 1536px){
  .screen {
      max-width: 1536px;
  }
}
.user-list {
  width: 100%;
  height: auto;
}

.ul-heading {
  font-size: 20px;
  padding: 10px;
  flex: 1;
  font-weight: 500;
}
@media(max-width:767px){
  .ul-heading {
    padding: 5px 10px;
  }
}
.ul-heading-placeholder {
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#action-col {
  width: 100px;
  min-width: 100px;
}

.add-dialog-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.add-dialog-content > div {
  flex: 1;
}

.add-user-input {
  margin: 10px 0 !important;
}

#avatar {
  display: flex;
  margin: 0 auto;
  width: 180px;
  height: 180px;
  border-radius: 100px;
}

#upload {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.unhide {
  display: block !important;
}

.avatar-panel {
  padding: 0 25px;
}

.styled-input-base {
  width: calc(100% - 40px);
}

.side-menu .Mui-selected:hover {
  background-color: #fff !important;
}

.side-menu .Mui-selected {
  background-color: #e5edfc !important;
  position: relative;
}



.side-menu .Mui-selected:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: #5a8dee;
}
.side-menu .Mui-selected > div > p,
.side-menu .Mui-selected > div > svg {
  color: #5a8dee !important;
}

.login-logo-placeholder {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.login-logo-placeholder h1 {
  margin: 0;
  display: flex;
  align-items: center;
  color: #104d9d;
  justify-content: center;
}

.login-tagline {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-tagline > span {
  font-size: 12px;
}

.logo > img,
.logo > h1 {
  cursor: pointer;
}

.loading-logo {
  width: 40px;
  height: 40px;
  filter: brightness(0) invert(1);
}

.MuiTabPanel-root {
  padding: 0 !important;
}

.callButton {
  background-color: #60e500 !important;
  margin: 15px !important;
}

.endCallButton {
  background-color: #da2f2f !important;
  margin: 15px !important;
}

.sipStatusPlaceholder {
  display: inline-flex;
  color: green;
  background-color: #d0f6bb;
  padding: 4px 8px;
  width: fit-content;
  height: 20px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  border-radius: 2px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}

.softphone-dialog {
  margin: 20px;
  width: 320px;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  position: fixed;
  right: -7px;
  bottom: 42px;
}

.dial-buttons,
.volumer {
  display: flex;
  align-items: center;
  justify-content: center;
}

textarea:focus,
input:focus {
  outline: none;
}

.dialpad-number {
  width: 29%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  font-size: 25px;
  font-weight: 200;
  cursor: pointer;
  margin: 0 6px;
}

.dialpad-number-circle {
  background-color: #e4e4e4;
  border-radius: 100px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  transition: all 200ms;
}

.dialpad-number-circle:hover {
  background-color: #f4f4f4;
}

.dialpad-number-circle:active {
  background-color: #c2c2c2;
}

.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

#phoneNumberInput:disabled {
  background-color: rgba(255, 255, 255, 0);
}

.calling-backdrop {
  background: linear-gradient(
    180deg,
    rgba(191, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%
  );
}

#phoneNumberInput {
  background-color: rgba(255, 255, 255, 0);
}

.animated {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.fast {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes radiate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(1.4, 1.4, 1.4);
  }
  100% {
    opacity: 0;
  }
}

@keyframes radiate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(1.4, 1.4, 1.4);
  }
  100% {
    opacity: 0;
  }
}

.radiate {
  -webkit-animation-name: radiate;
  animation-name: radiate;
}

.avatar {
  position: relative;
  height: 106px;
  width: 106px;
}

.avatar .status-circle,
.avatar .status-radiate {
  background: #666;
  border-radius: 100%;
  display: block;
  width: 106px;
  height: 106px;
  position: absolute;
  left: -3px;
  top: -3px;
  z-index: 0;
}

.avatar .status-circle.available,
.avatar .status-radiate.available {
  background: #7d2;
}

.search-box {
  padding: 14px;
}
@media (min-width: 1366px) {
  .search-box {
    max-width: 920px;
  }
}
#search-table tr td {
  padding-top: 20px;
}

#search-table tr:first-child td {
  padding-top: 0;
}

.hr-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hr-row label {
  width: fit-content;
}

.search-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.cot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 63px;
  padding: 0 10px 0 0;
  margin-bottom: 15px;
}

.dataStatus,
.dataSource {
  display: inline-flex;
}

.search-btn-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
}

/* .search-btn-row > div {
  gap: 10px;
  width: 100%;
  display: flex;
}
@media(max-width:480px){
  .search-btn-row > div {
    flex-direction: column;
  }
} */
.search-btn-row > button {
  width: calc((100% / 4) - 8px);
}
@media (max-width: 767px) {
  .search-btn-row > button {
    width: calc((100% / 2) - 5px);
  }
}
@media (max-width: 480px) {
  .search-btn-row > button {
    width: 100%;
  }
}

.overview-statistics {
  padding: 20px 0 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  width: calc(100% + 10px);
}
.overview-statistics > div {
  width: calc((100% / 4) - 10px);
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
}

@media only screen and (max-width: 767px) {
  .overview-statistics > div {
    width: calc((100% / 2) - 10px);
  }
  .overview-statistics > div:nth-child(2n + 1) {
    margin-right: 10px;
  }
}

.stats-placeholder {
  height: 60px;
  width: 60px;
  background-color: white;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  color: white;
}

.overview-statistics > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 10px;
  /* width: 110px; */
  border:none;
  border-radius:0.5rem;
  
}

.overview-statistics > div > div:nth-child(2) {
  color: gray;
  text-align: center;
}

#kh-table {
  overflow-x: scroll;
}

.checkout-total {
  padding: 14px;
  display: flex;
  flex-direction: row;
}

.checkout-label,
.checkout-money {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}

.checkout-label > div,
.checkout-money > div {
  margin-bottom: 5px;
}

.checkout-label > div:last-child,
.checkout-money > div:last-child {
  margin-bottom: 0;
}

.checkout-label {
  color: gray;
}

.checkout-money {
  font-weight: 600;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.pager {
  padding: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pager > div {
  display: flex;
  flex-direction: row;
}

.pager > div > div {
  display: flex;
  align-items: center;
}

li[data-range-key="Custom Range"] {
  content: "Tùy chỉnh";
}

.customer-detail-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 20px;
}

.maxWidth290 {
  max-width: 290px;
  margin-right: 10px;
  min-width: 290px;
}

.line-break {
  width: 100%;
  height: 1px;
}

.adjustWidth {
  width: calc(100% - 324px);
}

td {
  padding: 0;
}

.data-history-btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
}

.box-order-report {
  width: 50%;
}

.order-report {
  background: #85c2ff;
  padding: 10px;
}

.order-report span {
  color: #fff;
  font-size: 16px;
}

.setting-paper {
  margin-bottom: 15px;
}

/* Shared */

.loginBtn {
  text-align: center !important;
  height: 35px;
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */

.login-with-facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}

.login-with-facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
}

.login-with-facebook:hover,
.login-with-facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

.outgoing-message {
  background-color: #0080ff;
  width: max-content;
  max-width: 340px;
  word-wrap: break-word;
  margin: 0 0 0 auto;
  padding: 13px;
  border-radius: 10px;
  color: white;
  margin-top: 10px;
  min-width: 0;
}

.incoming-message {
  background-color: #f2f2f2;
  width: max-content;
  max-width: 340px;
  word-wrap: break-word;
  margin: 0 auto 0 0;
  padding: 13px;
  border-radius: 10px;
  margin-top: 10px;
  min-width: 0;
}

.participants-boxes {
  background-color: white;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 8px;
}

.selected-conversation {
  background-color: #e5edfc;
  height: 65px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 8px;
  border-radius: 10px;
  justify-content: space-between;
}

.search-conversation-box {
  background-color: #f3f3f4;
  width: 100%;
  font-size: 16px !important;
  border: 1px solid #ffffff;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 7px;
  border-radius:999px;
}

.below-name {
  max-width: 115px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #8b8b8b;
  font-size: 14px;
  margin-top: 5px;
}

.search-conversation-box .MuiInputAdornment-root {
  margin-right: 5px;
}

.search-conversation-box .MuiOutlinedInput-root {
  padding-left: 10px;
  border-radius: 999px;
}

.box-function-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #e5edfc;
  color:#5a8dee;
  border-left: 3px solid;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.info-box-title {
  color: #707287;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 0.938rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.function-box-content {
  margin-left: 10px;
}

.info-box-text {
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.participants-boxes:hover {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.profile-user-img {
  margin: 0 auto;
  width: 70px;
  height: 70px;
  padding: 3px;
  border: 3px dashed #e5edfc;
  border-radius: 100px;
  display: flex;
  justify-content: center;
}

.profile-username {
  
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  padding: 10px 0;
  font-size: 18px;
}

.avatar_image_small {
  border-radius: 50%;
  width: 36px;
}

.switch-cell .MuiSwitch-root {
  margin-left: -10px;
}

.box-upload {
  /* margin-right: 14px; */
  width: 180px;
  height: 150px;
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  border-radius: 0.5rem;
  position: relative;
  /* & > i {
      font-size: 22px;
      color: #1791f2;
      max-width: 8px 0;
    } */
}
.box-upload .box-img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.box-upload .box-img img {
  border-radius: 6px;
  width: 100%;
  height: 100%;
}
@media (max-width: 1170px) {
  .box-upload {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 1024px) {
  .box-upload {
    width: 65px;
    height: 65px;
  }
}

.text-diachi {
  height: 70px !important;
}

.box-img {
  position: absolute;
}

.btn-editImg {
}

.cursor-pointer {
  cursor: pointer;
}
@media(max-width:480px){
  .phonering-alo-phone{
    top:-8px !important;
  }
}
.phonering-alo-phone.phonering-alo-static {
  opacity: 0.6;
}

.phonering-alo-phone.phonering-alo-hover,
.phonering-alo-phone:hover {
  opacity: 1;
}

.phonering-alo-ph-circle {
  width: 45px;
  height: 45px;
  top: 13px;
  left: 13px;
  position: absolute;
  background-color: transparent;
  border-radius: 100% !important;
  border: 2px solid rgba(30, 30, 30, 0.4);
  border: 2px solid #bfebfc 9;
  opacity: 0.1;
  -webkit-animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
  animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.phonering-alo-phone.phonering-alo-active .phonering-alo-ph-circle {
  -webkit-animation: phonering-alo-circle-anim 1.1s infinite ease-in-out !important;
  animation: phonering-alo-circle-anim 1.1s infinite ease-in-out !important;
}

.phonering-alo-phone.phonering-alo-static .phonering-alo-ph-circle {
  -webkit-animation: phonering-alo-circle-anim 2.2s infinite ease-in-out !important;
  animation: phonering-alo-circle-anim 2.2s infinite ease-in-out !important;
}

.phonering-alo-phone.phonering-alo-hover .phonering-alo-ph-circle,
.phonering-alo-phone:hover .phonering-alo-ph-circle {
  border-color: rgba(48,209,88,.5);
}

.phonering-alo-phone.phonering-alo-green.phonering-alo-hover
  .phonering-alo-ph-circle,
.phonering-alo-phone.phonering-alo-green:hover .phonering-alo-ph-circle {
  border-color: rgba(48,209,88,.5);
}

.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-circle {
  border-color: rgba(48,209,88,.5);
}

.phonering-alo-phone.phonering-alo-gray.phonering-alo-hover
  .phonering-alo-ph-circle,
.phonering-alo-phone.phonering-alo-gray:hover .phonering-alo-ph-circle {
  border-color: #ccc;
  opacity: 0.5;
}

.phonering-alo-phone.phonering-alo-gray .phonering-alo-ph-circle {
  border-color: rgba(48,209,88,.5);
}

.phonering-alo-ph-circle-fill {
  width: 50px;
  height: 50px;
  top: 10px;
  left: 10px;
  position: absolute;
  background-color: #000;
  border-radius: 100% !important;
  border: 2px solid transparent;
  -webkit-animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.phonering-alo-phone.phonering-alo-active .phonering-alo-ph-circle-fill {
  -webkit-animation: phonering-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
  animation: phonering-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
}

.phonering-alo-phone.phonering-alo-static .phonering-alo-ph-circle-fill {
  -webkit-animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
  animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
  opacity: 0 !important;
}

.phonering-alo-phone.phonering-alo-hover .phonering-alo-ph-circle-fill,
.phonering-alo-phone:hover .phonering-alo-ph-circle-fill {
  background-color: rgba(48,209,88,1);
  background-color: rgba(48,209,88,.9);
  opacity: 0.75 !important;
}

.phonering-alo-phone.phonering-alo-green.phonering-alo-hover
  .phonering-alo-ph-circle-fill,
.phonering-alo-phone.phonering-alo-green:hover .phonering-alo-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  background-color: rgba(48,209,88,.9);
  opacity: 0.75 !important;
}

.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-circle-fill {
  background-color: rgba(48,209,88,.9);
  background-color: #a6e3fa 9;
}

.phonering-alo-phone.phonering-alo-gray.phonering-alo-hover
  .phonering-alo-ph-circle-fill,
.phonering-alo-phone.phonering-alo-gray:hover .phonering-alo-ph-circle-fill {
  background-color: rgba(204, 204, 204, 0.5);
  background-color: #ccc 9;
  opacity: 0.75 !important;
}

.phonering-alo-phone.phonering-alo-gray .phonering-alo-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  opacity: 0.75 !important;
}

.phonering-alo-ph-img-circle {
  width: 30px;
  height: 30px;
  top: 20px;
  left: 20px;
  position: absolute;
  background: rgba(30, 30, 30, 0.1)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNmlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjarY6xSsNQFEDPi6LiUCsEcXB4kygotupgxqQtRRCs1SHJ1qShSmkSXl7VfoSjWwcXd7/AyVFwUPwC/0Bx6uAQIYODCJ7p3MPlcsGo2HWnYZRhEGvVbjrS9Xw5+8QMUwDQCbPUbrUOAOIkjvjB5ysC4HnTrjsN/sZ8mCoNTIDtbpSFICpA/0KnGsQYMIN+qkHcAaY6addAPAClXu4vQCnI/Q0oKdfzQXwAZs/1fDDmADPIfQUwdXSpAWpJOlJnvVMtq5ZlSbubBJE8HmU6GmRyPw4TlSaqo6MukP8HwGK+2G46cq1qWXvr/DOu58vc3o8QgFh6LFpBOFTn3yqMnd/n4sZ4GQ5vYXpStN0ruNmAheuirVahvAX34y/Axk/96FpPYgAAACBjSFJNAAB6JQAAgIMAAPn/AACA6AAAUggAARVYAAA6lwAAF2/XWh+QAAAB/ElEQVR42uya7W3CMBCG31QM4A1aNggTlG6QbpBMkHYC1AloJ4BOABuEDcgGtBOETnD9c1ERCH/lwxeaV8oPFGP86Hy+DxMREW5Bd7gRjSDSNGn4/RiAOvm8C0ZCRD5PSkQVXSr1nK/xE3mcWimA1ZV3JYBZCIO4giQANoYxMwYS6+xKY4lT5dJPreWZY+uspqSCKPYN27GJVBDXheVSQe494ksiEWTuMXcu1dld9SARxDX1OAJ4lgjy4zDnFsC076A4adEiRwAZg4hOUSpNoCsBPDGM+HqkNGynYBCuILuWj+dgWysGsNe8nwL4GsrW0m2fxZBq9rW0rNcX5MOQ9eZD8JFahcG5g/iKT671alGAYQggpYWvpEPYWrU/HDTOfeRIX0q2SL3QN4tGhZJukVobQyXYWw7WtLDKDIuM+ZSzscyCE9PCy5IttCvnZNaeiGLNHKuz8ZVh/MXTVu/1xQKmIqLEAuJ0fNo3iG5B51oSkeKnsBi/4bG9gYB/lCytU5G9DryFW+3Gm+JLwU7ehbJrwTjq4DJU8bHcVbEV9dXXqqP6uqO5e2/QZRYJpqu2IUAA4B3tXvx8hgKp05QZW6dJqrLTNkB6vrRURLRwPHqtYgkC3cLWQAcDQGGKH13FER/NATzi786+BPDNjm1dMkfjn2pGkBHkf4D8DgBJDuDHx9BN+gAAAABJRU5ErkJggg==)
    no-repeat center center;
  border-radius: 100% !important;
  border: 2px solid transparent;
  -webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
  animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background-size:20px 20px;
}

.phonering-alo-phone.phonering-alo-active .phonering-alo-ph-img-circle {
  -webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out !important;
  animation: phonering-alo-circle-img-anim 1s infinite ease-in-out !important;
}

.phonering-alo-phone.phonering-alo-static .phonering-alo-ph-img-circle {
  -webkit-animation: phonering-alo-circle-img-anim 0 infinite ease-in-out !important;
  animation: phonering-alo-circle-img-anim 0 infinite ease-in-out !important;
}

.phonering-alo-phone.phonering-alo-hover .phonering-alo-ph-img-circle,
.phonering-alo-phone:hover .phonering-alo-ph-img-circle {
  background-color: rgba(48,209,88,1);
}

.phonering-alo-phone.phonering-alo-green.phonering-alo-hover
  .phonering-alo-ph-img-circle,
.phonering-alo-phone.phonering-alo-green:hover .phonering-alo-ph-img-circle {
  background-color: #75eb50;
  background-color: #75eb50 9;
}

.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-img-circle {
  background-color: rgba(48,209,88,1);
  background-color: rgba(48,209,88,.9);
}

.phonering-alo-phone.phonering-alo-gray.phonering-alo-hover
  .phonering-alo-ph-img-circle,
.phonering-alo-phone.phonering-alo-gray:hover .phonering-alo-ph-img-circle {
  background-color: #ccc;
}

.phonering-alo-phone.phonering-alo-gray .phonering-alo-ph-img-circle {
  background-color: rgba(48,209,88,1);
}

@-webkit-keyframes phonering-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.5) skew(1deg);
    -webkit-opacity: 0.1;
  }
  30% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    -webkit-opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    -webkit-opacity: 0.1;
  }
}

@-webkit-keyframes phonering-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes phonering-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes phonering-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.5) skew(1deg);
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes phonering-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.5) skew(1deg);
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-webkit-keyframes phonering-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@keyframes phonering-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes phonering-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@keyframes phonering-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }
}

.close-phone {
  position: absolute;
  cursor: pointer;
  /* left: -44px; */
  padding: 10px;
  background: #e71716;
  /* top: 55px; */
  color: #fff;
  /* transition: 1s ease-in-out; */
}

.close-phone:hover svg {
  transform: rotate(180deg);
  transition: 1s ease-in-out;
}

.chat-inbox {
  overflow: auto;
  height: calc(100vh - 195px);
}

.inbox-more-btn {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 50px;
  color: #0c9f6e;
  cursor: pointer;
}

.inbox-more-btn:hover {
  background-color: #f4f4f4;
}

.unread-bold {
  font-weight: 700;
  color: black;
}

.unread-count {
  width: 20px;
  height: 20px;
  background-color: #ee5050;
  border-radius: 50%;
  color: white;
  margin-right: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-upload-span {
  display: flex;
  font-size: 14px;
}

.box-submitImport {
  display: flex;
  justify-content: flex-end;
}
@media(min-width:480px){
  .box-submitImport {
    margin-right:30px;
  }
}
.ant-upload-list-item-card-actions-btn {
  background: none;
  border: none;
  color: red;
}

label[class*="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated"] {
  top: -5px !important;
  left: -5px;
  padding: 0 10px;
  font-size: 14px;
  background: #fff;
}

.MuiFormLabel-root.Mui-focused{
  color: #5a8dee!important;
  background: #fff;
}

label[class*="MuiInputLabel-sizeSmall"] {
  top: 2px !important;
    left: -5px;
    padding: 0 10px;
    font-size: 14px;
    background: #fff;
}

[class*="MuiOutlinedInput"] {
  height: 40px !important;
  line-height: 40px !important;
  border: 1px solid #CDD2D7 !important;
  color: rgb(112, 114, 135) !important;
  font-size: 0.875rem !important;
}


[class*="MuiOutlinedInput"] > div,
[class*="MuiOutlinedInput"] input,
[class*="MuiOutlinedInput"] fieldset {
  border: none !important;
}

[class*="MuiAutocomplete-endAdornment"] {
  top: -2px !important;
  z-index:10;
}

.fix-muibox .MuiBox-root {
  position: inherit !important;
}

.fix-muibox [class*="MuiOutlinedInput-input MuiInputBase-input"] {
  height: 17px !important;
  padding: 0 !important;
}

[class*="MuiMenuItem-root MuiMenuItem-gutters MuiButtonBase-root"] {
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 6px 16px !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none !important;
  vertical-align: middle !important;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex !important;
  justify-content: left !important;
  display: -webkit-flex;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

[class*="MuiButtonBase-root-MuiMenuItem-root"]:hover {
  text-decoration: none;
  background-color: transparent !important;
}

/* CDR report */
.btn-play {
  min-width: 80px;
  padding: 0 10px;
  background: #5a8dee;
  height: 36px;
  line-height: 36px;
  color: #fff;
  border: none;
  border-radius:0.5rem;
  cursor: pointer;
  box-shadow: 0 2px 6px 0 rgb(59 130 246 / 50%);
}

.user-list, .order-list{
  border-radius: 0.5rem !important;
  border:none !important;
  box-shadow:0 4px 25px 0 rgb(168 180 208 / 10%) !important;
}
.btn-modal-group {
  padding: 40px 0 0;
}
@media (min-width: 481px) {
  .btn-modal-group {
    display: flex;
    justify-content: space-evenly;
  }
}
@media (max-width: 480px) {
  .btn-modal-group > div {
    margin-bottom: 10px;
  }
  .btn-modal-group .search-row-button {
    width: 100%;
  }
}
/* transform */
@-webkit-keyframes slideIn {
  0% {
    -webkit-transform : translateY(1rem);
            transform : translateY(1rem);
    opacity : 0;
  }
  100% {
    -webkit-transform : translateY(0rem);
            transform : translateY(0rem);
    opacity : 1;
  }
  0% {
    -webkit-transform : translateY(1rem);
            transform : translateY(1rem);
    opacity : 0;
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform : translateY(1rem);
            transform : translateY(1rem);
    opacity : 0;
  }
  100% {
    -webkit-transform : translateY(0rem);
            transform : translateY(0rem);
    opacity : 1;
  }
  0% {
    -webkit-transform : translateY(1rem);
            transform : translateY(1rem);
    opacity : 0;
  }
}
/* new style  version 2*/
.heading{
  font-size: 1.2rem;
  margin: 0;
  font-weight: 500;
  margin-bottom: 0.2rem;
}
.upper-menu .item{
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 17px;
}

.upper-menu .name-sidebar{
  color: rgb(112, 114, 135);
}
.box-right{
  display: flex;
  
  position: relative;
  padding-right: 5px;
}

@media(min-width:480px){
  .box-right{
    margin-left: auto;
    padding-right: 12px;
  }
}

.box-drp .menuExpand{
  min-width: 176px;
  font-size: 0.875rem;
  padding: 5px;
  box-shadow:0 4px 25px 0 rgb(168 180 208 / 10%);
  border: 1px solid #d4d8dd;
  border-radius:0.5rem;
  display: none;
  position: absolute;
  right:0;
  top:40px;
  background: #fff;
  z-index: 20;
}
.box-drp .menuExpand.notifi-wrapper{
  min-width: 300px;
  width: 300px;
  text-align:left;
  padding: 10px;
}
@media(max-width:480px){
  .box-drp .menuExpand.notifi-wrapper{
    right:-55px;
}}
.box-drp .menuExpand .menuItem{
  padding: 5px 10px;
  color:rgb(112, 114, 135);
  cursor: pointer;
}
.box-drp .menuExpand .menuItem:hover{
  background: #e5edfc;
  color:#5a8dee
}
.box-drp .menuExpand.active{
  -webkit-animation-duration: 0.3s; 
  animation-duration: 0.3s; 
  webkit-animation-fill-mode: both; 
  animation-fill-mode: both;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  display: block;
}
.boxsearch-sidebar{
  position: fixed;
  width: 100%;
  background: #fff;
  right: 0;
  top: 0;
  z-index: 9999;
  height: 100%;
  -webkit-box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
  
  webkit-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
    -webkit-transform: translateX(440px);
    -ms-transform: translateX(440px);
    transform: translateX(440px);
    padding: 10px 0 15px 0;
}

.boxsearch-sidebar.active{
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    padding-top:0;
}
@media(min-width:480px){
  .boxsearch-sidebar{
    width: 440px;
    border: 1px solid #d4d8dd;
  }
}
@media(min-width:480px){
  .boxsearch-sidebar{
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    position: absolute;
    top: 40px;
    right: 14px;
    height: inherit;
    border-radius:0.5rem;
    display: none;
  }
  .boxsearch-sidebar.active{
    display: block;
  }
  .boxsearch-sidebar .ul-heading{
    display: none !important;
  }
}
.boxsearch-sidebar .ul-heading{
  padding: 10px;
}
/* card dashboard */

.box-card {
  padding: 5px 0;
  display: flex;
  
  width: calc(100% + 15px);
}
@media(min-width:768px){
  .box-card{
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.box-card .item{
  width: calc((100% / 6) - 15px);
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  color: #fff;
  border-radius: 0.5rem;
  margin-bottom: 15px;
  margin-right: 15px;
}
@media(max-width:1531px) and (min-width:767px){
    .box-card .item{
      width: calc((100% / 3) - 15px);
    }
    .box-card .item:nth-child(3n+1){
      margin-right: 15px;
    }
}

@media (max-width: 767px) and (min-width:480px){
  
  .box-card .item {
    width: calc((100% / 2) - 15px);
  }
  .box-card .item:nth-child(2n+1){
    margin-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .box-card{
    overflow-y:scroll;
  }
  .box-card .item {
    min-width: 250px;
    
  }
}
.box-card .item .box-content{
  padding: 20px;
}
.box-card .item .title{
  
  margin:0;
  font-weight: 600;
}
.box-card .item .text-bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-card .item .number{
  font-size: 30px;
  font-weight: 600;
}
.bg-c-blue{
  background: linear-gradient(45deg,#4099ff,#73b4ff);
}
.bg-c-red{
  background: linear-gradient(45deg,#FF5370,#ff869a)
}
.bg-c-yellow{
  background: linear-gradient(45deg,#FFB64D,#ffcb80);
}
.bg-c-green{
  background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}
.bg-c-ibiza{
  background: #ee0979;
    background: -webkit-linear-gradient( 45deg , #ee0979, #ff6a00)!important;
    background: linear-gradient( 45deg , #ee0979, #ff6a00)!important;
    color: #fff;
}
.bg-c-cosmic{
  background: linear-gradient(to right, #8e2de2, #4a00e0);
}
.bg-bluepastel{
  background: #e5edfc;
}
/* card dashboard */

.customer-overview {
  padding: 5px 0 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  width: calc(100% + 15px);
}
.customer-overview .item{
  width: calc((100% / 4) - 15px);
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  background: #fff;
  border-radius: 0.5rem;
  margin-bottom: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-left:3px solid #5a8dee;
}

@media (max-width: 992px) and (min-width:767px){
  .customer-overview .item {
    width: calc((100% / 3) - 15px);
    
  }
  .customer-overview .item:nth-child(3n+1){
    margin-right: 15px;
  }
}
@media (max-width: 767px){
  
  .customer-overview .item {
    width: calc((100% / 2) - 15px);
  }
  .customer-overview .item:nth-child(2n+1){
    margin-right: 15px;
  }
}
@media(max-width:480px){
  .customer-overview .item {
    padding:5px;
  }
}
.customer-overview .item .box-icon{
  position: relative;
  border-radius:10px;
  width: 3rem;
  height: 3rem;
  background: #5a8dee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  flex:0 0 3rem;
}
.customer-overview .item .box-icon:after, .customer-overview .item .box-icon:before{
  content: "";
    position: absolute;
    width: 8px;
    height: 54px;
    background-color: rgba(255,255,255,.1);
    left: 16px;
    -webkit-transform: rotate(32deg);
    transform: rotate(32deg);
    top: -5px;
    -webkit-transition: all .4s;
    transition: all .4s;
}
.customer-overview .item .box-icon:after{
  left: -12px;
    width: 12px;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.customer-overview .item .title{
  
  margin:0;
  font-size: .875rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
  color: #707287;
}

.customer-overview .item .number{
  font-size: 1.5rem;
  font-weight: 500;
}

.boxgroup > *{
  width: 100%;
}
@media(max-width:480px){
  .boxgroup > *{
    margin-bottom:10px;
  }
}
@media(min-width:480px){
  .boxgroup{
    display: flex;
  }
  .boxgroup > *{
    width: calc(100% /3);
  }
  .boxgroup > *:not(:first-child){
    margin-left:10px;
  }
  .boxgroup.two > *{
    width: calc(100% /2);
  }
}
/* box upload */
.modal-boxupload{
  padding: 0;
  background: #fff;
}
@media(min-width:480px){
  .modal-boxupload{
    padding: 10px 30px 0 30px;
    max-width: 600px;
    margin: 0 auto;
  }
}
.modal-boxupload .form-upload{
    height: 167px;
    display: flex;
    cursor: pointer;
    margin: 15px 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    border: 2px dashed #5a8dee;
    position: relative;
}
@media(min-width:480px){
  .modal-boxupload .form-upload{
    margin: 30px 0;
  }
}
.modal-boxupload .content-upload{
  color:#5a8dee;
  text-align:center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.modal-boxupload .content-upload p{
  margin-bottom: 0;
  margin-top: 15px;
  font-size: 16px;
  font-weight:400;
}
.modal-boxupload .content-upload i{
  font-size:50px;
}
.modal-boxupload .ant-upload-list-text-container{
  height: 50px;
  line-height: 50px;
  border-radius: 0.25rem;
  background: #e5edfc;
  padding: 0 10px;
  margin-bottom:10px;
}
.modal-boxupload .ant-upload-list-item-name{
  margin:0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.modal-boxupload .anticon svg{
  width: 1.2em;
    height: 1.2em;
}
.modal-boxupload .ant-upload-list-item-card-actions .anticon svg{
  color:rgb(244,106,106);
  position: relative;
  top:-3px;
}

::-webkit-file-upload-button{
  font:initial !important;
}

.box-card-sumary{
  display: flex;
  border-radius:0.5rem;
  box-shadow: 0 4px 25px 0 rgb(168 180 208 / 10%);
  margin-bottom: 20px;
  background: #C0BBF7;
  background: -webkit-linear-gradient(top left, #C0BBF7, #355FBF);
  background: -moz-linear-gradient(top left, #C0BBF7, #355FBF);
  background: linear-gradient(to bottom right, #C0BBF7, #355FBF);
  margin-top: 20px;
}
@media(min-width:768px){
  .box-card-sumary{
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.box-card-sumary .item{
  display: flex;
  padding: 20px 25px;
  width: calc(100% /3);
  min-height: 102px;
  position: relative;
}
@media(min-width:1536px){
  .box-card-sumary .item{
    width: calc(100% /6);
  }
  .box-card-sumary .item:not(:last-child){
    border-right:1px solid rgb(255, 255, 255,0.2) !important;
  }
}
.box-card-sumary .item:nth-child(n+1){
  border-right:1px solid rgb(255, 255, 255,0.2);
}
.box-card-sumary .item:nth-child(n+3){
  border-top:1px solid rgb(255, 255, 255,0.2);
}
.box-card-sumary .item:nth-child(3n+3){
  border-right:0;
}
.box-card-sumary .item .box-icon{
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  
  color: #fff;
  border-radius: 50%;
    background: rgba(255,255,255,0.2);
    border: 1px dashed rgba(255,255,255,0.5);
    position: absolute;
    top: 10px;
    right: 20px;
}
.box-card-sumary .item .box-icon .fas{
  font-size: 20px;
}
.box-card-sumary .item .box-content{
  
  color: #fff;
}
.box-card-sumary .item .title{
  font-weight: 600;
  font-size: 16px;
}
.box-card-sumary .item .number{
  font-weight: bold;
  font-size: 32px;

}
@media only screen and (max-width: 767px) {
  .box-card-sumary{
    overflow-y:scroll;
    width: calc(100% + 15px);
  }
  .box-card-sumary .item {
    min-width: 250px;
    width: inherit;
  }
  .box-card-sumary .item:not(:last-child){
    border-right:1px solid rgb(255, 255, 255,0.2) !important;
  }
}
.box-note{
  padding: 10px 15px;
  border-radius:0.5rem;
  text-align:left;
  border:2px dashed #e5edfc;
}
.box-note .list{
  margin-top:10px;
}
.box-note .list .item{
  display: flex;
}
.box-note .item .box-avatar{
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: block;
  text-align:center;
  border-radius:50%;
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: bold;
  color:#fff;
  background: #5a8dee;
  flex:0 0 36px;
}
.box-note .item .box-content{
  width: calc(100% - 40px);
}
.box-note .item .box-content .name{
  font-size: 15px;
  font-weight: 500;
  color: #222;
}
.box-note .item .box-content p{
  color: #222;
}
.box-note .item .box-content .time{
  color: #999;
  font-size: 13px;
}
.box-note .item:not(:first-child){
  border-top:1px dashed #e5edfc;
  padding-top:10px;
  margin-top:10px;
}

.box-note {
  position: relative;
  z-index: 2;
  
  height: 296px;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media(min-width:768px){
  .box-note::-webkit-scrollbar {
    width: 3px;
    background-color: #eee; }
  
  .box-note::-webkit-scrollbar-thumb {
    background-color: #e5edfc;
    border-radius: 999px; }
  }

.box-note-form{
  display: flex;

}
.box-note-form textarea{
  margin-right:10px;
  height: inherit;
}
/* all status */
.bg-success {
  color: #34c38f;
    background-color: rgba(52,195,143,.18);
}
.bg-failed {
  color: #f46a6a;
    background-color: rgba(244,106,106,.18);
}
.bg-pending{
  color: #f1b44c;
    background-color: rgba(241,180,76,.18);
}
.text-status{
  padding: 3px 5px;
  border-radius:0.3rem;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

  @media(max-width:600px){
    .chat-panel-right{
    position: fixed;
    right: 0px;
    background: #fff;
    top: 57px;
    webkit-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }
  .chat-panel-right.active{
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
  }
  .chat-panel-right .box-heading >div{
    padding-left:45px !important;
  }
}
#conversations-panel{
  width: 100%;
}
@media(min-width:600px){
  #conversations-panel{
    width: 300px;
    border-right:1px solid #E5E5E5;
  }
}


.box-step-content{
  text-align:center;
}
.box-account-info{
  text-align: left;
  padding: 5px 10px;
  border-bottom: 1px dashed #eee;
  margin-bottom:5px;
}
.box-account-info .user-name{
  font-size:0.875rem;
  font-weight: 600;
}
.box-account-info .user-status{
  color:#707287;
  text-transform:capitalize;
  letter-spacing: 0.2rem;
}

.user-profile .box-avatar{
  width: 70px;
    height: 70px;
    margin: 0 auto;
    border-radius: 50%;
    display: block;
}
.zalo-wrapper{
  position: relative;
}
.circle-expand{
 
    text-align: center;
    display: block;
    
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 20;
    cursor: pointer;
    font-size: 18px;
    webkit-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
    display: none;
}

@media(min-width:992px){
  .zalo-wrapper {
    display: flex;
  }
  .zalo-wrapper .circle-expand{
    display: block;
    
  }
  .zalo-wrapper.expand .circle-expand{
    color: #5a8dee;
  }
  .zalo-wrapper .sidebar-nav{
    width: 300px;
    border-right: 1px solid #f6f8fa;
    
  }
  .zalo-wrapper .sidebar-nav{
    padding:20px;
  }
  .zalo-wrapper .tab-content{
    webkit-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
    width: calc(100% - 300px);
  }
  
  .zalo-wrapper.expand .sidebar-nav{
    opacity: 0;
    transform: translateX(-100%);
    position: absolute;
    
  }
  .zalo-wrapper.expand .tab-content{
    width: 100%;
  }
  .zalo-wrapper .sidebar-nav{
    margin-top: 36px;
  }
  .zalo-wrapper .tab-content{
    margin-top: 30px;
  }
}
.zalo-wrapper .sidebar-nav{
  padding:10px;
  webkit-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  transition: all .2s ease-in;
}
.zalo-wrapper .sidebar-nav .menuItem{
  padding: 10px 15px;
  position: relative;
  cursor: pointer;
  border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
.zalo-wrapper .sidebar-nav .menuItem.active{
  background-color: #e5edfc;
  color:#5a8dee;
}
.zalo-wrapper .sidebar-nav .menuItem.active:before{
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background: #5a8dee;
}


@media(min-width:768px){
  .zalochat-wrapper{
    display: flex;
    
  }
  .zalochat-wrapper .box-chat-listing{
    width: 320px;
    height: 500px;
    margin-right: 10px;
    padding-right: 8px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .fb-list{
    width: 104%;
    height: 240px;
    margin-right: 10px;
    padding-right: 8px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .zalochat-wrapper .wrapper-chat{
    width: calc(100% - 330px);
    
  }
  .zalo-wrapper .tab-content{
    padding: 20px;
  }
  .wrapper-chat .msg-content {
  height: 370px;
  
}
.wrapper-chat .msg-content {
  
  width: calc(100% - 5px);
}
.wrapper-chat .chat-msg{
  height: 380px;
}
}
@media(min-width:1367px){
  .wrapper-chat .msg-content{
    height: 484px;
  }
  .wrapper-chat .chat-msg {
    height: 555px;
  }
  .zalochat-wrapper .box-chat-listing{
    height: 670px;
  }
  .wrapper-chat .msg-content {
    height: 530px;
  }
  #zalofollower .wrapper-chat .msg-content {
    height: 480px;
  }
}
.wrapper-chat .msg-content {
  
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top:15px;
  padding-left: 20px;
  padding-right: 15px;
  width: calc(100% - 5px);
}

.zalo-wrapper .tab-content{
  
  padding: 10px;
  -moz-transition: all 200ms ease-in;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.zalo-wrapper .zalo-logo{
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  border-radius:50%;
}
.zalo-wrapper .zalo-logo img{
  width: 100%;
  height: 100%;
  border-radius:50%;
}

.wrapper-chat .chat-box-top{
  padding: 10px;
  border: 1px solid #EBEBEB;
}
.wrapper-chat .chat-box-top .chat-user{
  display: flex;
  align-items: center;
}
.wrapper-chat .chat-box-top .chat-user .total-msg{
  font-weight: bold;
  margin-left:auto;
  
}

.wrapper-chat .chat-box-top .chat-user .username{
  font-weight: bold;
}

.wrapper-chat .chat-box-top .chat-user .photo{
  width: 36px;
  height: 36px;
  border-radius:50%;
  margin-right:10px;
  overflow:hidden;
}
.wrapper-chat .chat-box-top .chat-user .photo img{
  width: 100%;
  height: 100%;
}
.wrapper-chat .chat-msg {
  background: #F7F7F7;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  position: relative;
}



@media(min-width:768px){
  .wrapper-chat .msg-content::-webkit-scrollbar {
    width: 3px;
    background-color: #eee; }
  
  .wrapper-chat .msg-content::-webkit-scrollbar-thumb {
    background-color: #e5edfc;
    border-radius: 999px; 
  }
  .zalochat-wrapper .box-chat-listing::-webkit-scrollbar {
    width: 3px;
    background-color: #eee; }
  
  .zalochat-wrapper .box-chat-listing::-webkit-scrollbar-thumb {
    background-color: #e5edfc;
    border-radius: 999px; 
  }

  .fb-list::-webkit-scrollbar {
    width: 3px;
    background-color: #eee; }
  
  .fb-list::-webkit-scrollbar-thumb {
    background-color: #e5edfc;
    border-radius: 999px; 
  }
  .fb-list::-webkit-scrollbar {
    width: 3px;
    background-color: #eee; }
  
    .fb-list::-webkit-scrollbar-thumb {
    background-color: #e5edfc;
    border-radius: 999px; 
  }

  }
 

.wrapper-chat .msg-content .msg-box {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border-radius: 15px 15px 0px 15px;
  
  padding: 8px 10px;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 16px;
  text-align: left;
  max-width: 800px;
}
.wrapper-chat .msg-content .text-right .msg-seller{
  justify-content: flex-end;
}
.wrapper-chat .msg-content .msg-seller{
  display: flex;
}
.wrapper-chat .msg-content .msg-seller .photo {
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  
  margin-right: 8px;
}

.wrapper-chat .msg-content .text-right .msg-seller .msg-box {
  
  background: rgb(90, 141, 238);
  color: #fff;
  margin-top:5px;
}
.wrapper-chat .msg-content .msg-time{
  font-size: 12px;
  opacity:0.6;
}
.wrapper-chat .chat-input-holder {
  background: #fff;
  -webkit-box-shadow: 0px 2px 10px rgb(0 0 0 / 15%);
  box-shadow: 0px 2px 10px rgb(0 0 0 / 15%);
  border-radius: 10px;
  position: relative;
}
.wrapper-chat .chat-input-holder textarea{
  padding-right:60px!important;
  height:64px!important;
  border:none !important;
}
.wrapper-chat{
  position: relative;
  
}
.wrapper-chat .loading{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:20;
}
.spinning-loader {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #e5edfc;
  border-left-color: #5a8dee;
  background: transparent;
  animation: rotate-s-loader 1s linear infinite;
  margin: 1rem auto;
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}


@media(max-width:767px){
  .wrapper-chat .chat-box-top{
    padding-left:40px;
  }
  .zalochat-wrapper .wrapper-chat{
    position: fixed;
    top: 56px;
    left: 0;
    background: #fff;
    height: calc(100% - 56px);
    width: 100%;
    webkit-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);

  }

  .zalochat-wrapper .wrapper-chat.active{
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
  }
  .wrapper-chat .chat-box-top .chat-user .total-msg{
    font-size: 12px;
  }
  .wrapper-chat .msg-content{
    height: calc(100vh - 240px);
  }
}



.zalochat-wrapper .box-chat-listing .heading{
  margin-bottom: 1rem;
}
.zalochat-wrapper .box-chat-listing .item{
  padding: 10px;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    align-items: cennter;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
   
}
.zalochat-wrapper .box-chat-listing .item.bg-blue{
  background:linear-gradient(to right, #9aa5ea, #557cf0);
  /* box-shadow: 0 5px 15px 0 rgb(59 130 246 / 50%); */
  color:#fff;
}
.zalochat-wrapper .box-chat-listing .item.bg-blue .fas{
  padding: 6px;
    border-radius: 50%;
    border: 1px dashed #9aa5ea;
    position: absolute;
    right: 10px;
    font-size: 12px;
}
.zalochat-wrapper .box-chat-listing .item .photo{
  position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}
.zalochat-wrapper .box-chat-listing .item .photo img{
  width: 100%;
  height: 100%;
  border-radius:50%;
}

.zalochat-wrapper .box-chat-listing .item .info{
  width: calc(100% - 60px);
}
.zalochat-wrapper .box-chat-listing .item .info .username{
  font-weight: bold;
}
.zalochat-wrapper .wrapper-chat .msg-content .msg-box{
  max-width: 400px;
}
.fbold{
  font-weight: bold;
}

.accordion-item__inner {
  max-height: 0;
  overflow: hidden;
  text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition-duration: 0.5s;
  transition-property: max-height;
  z-index: 1;
  position: relative;
}
.accordion-item.open .accordion-item__inner {
  max-height: 100rem;
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition-duration: 0.5s;
  transition-property: max-height;
}

.wavy-line {
  width: 24px;
  height: 36px;
  overflow: hidden;
  margin: 0;
}
.wavy-line:before {
  content: attr(data-text);
  position: relative;
  top: -8px;
  color: rgba(0,0,0,0);
  font-size: 1em;
  text-decoration-style: wavy;
  text-decoration-color: #25173A;
  text-decoration-line: underline;
  animation: animate .9s linear infinite;
  -webkit-animation: animate .9s linear infinite;
}

@keyframes animate {
  0% { left: -0px; }
  100% { left: -30px;}
}
@-webkit-keyframes animate {
  0% { left: -0px; }
  100% { left: -30px;}
}

.wavy-line-blue:before { text-decoration-color: #fff; }

.box-info-customer{
  padding: 15px;
}
.box-info-customer .item{
  display: flex;
  margin-bottom: 5px;
}
.box-info-customer .item .label-name{
  min-width: 100px;
  margin-right:10px;
  font-weight: 500;
}

/*  chat */
.notifi-wrapper .heading{
  margin-bottom: 10px;
}
.notify-list{
  height: 330px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.notify-list .item .status {
  width: 8px;
  height: 8px;
  line-height: 8px;
  border-radius: 50%;
  display: block;
  background: -webkit-gradient(linear, left top, left bottom, from(#22BA4F), to(rgba(34, 186, 79, 0))), #19A442;
  background: linear-gradient(180deg, #22BA4F 0%, rgba(34, 186, 79, 0) 100%), #19A442;
  position: absolute;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 15px;
  left: 10px;
}
.notify-list .item{
  display: flex;
  margin-bottom: 15px;
  border-radius:0.5rem;
  padding: 5px;
  cursor: pointer;
  position: relative;
  webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s;
}

.notify-list .item:hover{
  background: #e5edfc;
  
}
.notify-list .item:hover .btn-del{
  opacity:1;
  
}
.notify-list .item .btn-del{
  width: 28px;
    height: 24px;
    line-height: 24px;
    position: absolute;
    right: 5px;
    top: 12px;
    background: #fff;
    display: block;
    text-align: center;
    border-radius: 0.3rem;
    opacity:0;

}
.notify-list .item .btn-del i{
  font-size: 12px;
  color: #5a8dee;
}
.notify-list .item .btn-del i.color-gray{
  color: #777;
}
.notify-list .item .photo{
  position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px; 
    flex:0 0 40px;
}

.notify-list .item .photo img{
  width: 100%;
  height: 100%;
  border-radius:50%;
}

.notify-list .item .info .text-msg{
  color: #707287;
  max-height:42px;
  display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
}
.notify-list .item .info .source{
  font-size: 12px;
    color: #5a8dee;
    letter-spacing: 0.2rem;
}
.notify-list .item .info .username{
  font-weight: 500;
  color: #222;
  margin-bottom: 0;
}


  .notify-list::-webkit-scrollbar {
    width: 3px;
    background-color: #eee; }
  
  .notify-list::-webkit-scrollbar-thumb {
    background-color: #e5edfc;
    border-radius: 999px; }
    .blocker {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: ' ';
      z-index:5;
    }
    .boxsearch-content{
      position: relative;
      z-index:20;
    }

   /* settings page */
   .tabs-settings{
    display: flex;
    margin-bottom: 0;
   }
   .tabs-settings .icon-box{
    width: 25%;
    padding: 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    margin-right: 10px;
    cursor: pointer;
    font-weight: 500;
    box-shadow: 0 4px 25px 0 rgb(168 180 208 / 10%)
   }
   .tabs-settings .icon-box >span{
    margin-left: 5px;
   }
   .tabs-settings .icon-box.active{
    border-top:2px solid #5a8dee;
    background: #e5edfc;
    color: #5a8dee;
   }

   @media(max-width:768px){
    .tabs-settings{
      width: 710px;
      overflow-y: scroll;
      white-space: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
    }
    .tabs-settings .icon-box{
      width: inherit;
      min-width: 170px;
    }
   }

   .box-phone-customer .title{
    font-weight: 600;
   } 
   .box-phone-customer .customer-name{
    cursor: pointer;
    text-transform: capitalize;
   }

   .pu-phone{
    width:350px;
    font-size: 1rem;
    padding: 10px;
    box-shadow: 0 4px 25px 0 rgb(168 180 208 / 10%);
    border: 1px solid #d4d8dd;
    border-radius: 0.5rem;
    position: absolute;
    right: 15px;
    top: 75px;
    background: #fff;
    z-index: 20;
   }
   .list-support-registercall{
    position: fixed;
    right: -100%;
    transform: translateX(-50%);
    top: 30%;
    background: #fff;
    padding: 6px;
    border-radius: 10px 0 0 10px;
    width: 48px;
    box-shadow: 0 0 5px #0000001a;
    z-index: 99;
    webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
   }
   .list-support-registercall.active{
    right:-24px;
   }
   .smart-button{
    display: flex;
    margin-top: 5px;
   }
   .smart-button .btn{
    color: #f46a6a;
    background-color: rgba(244,106,106,.18);
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align:center;
    display: block;
    border-radius:0.3rem;
    margin-right: 5px;
    cursor: pointer;
   }